import { LabelProps, Root } from "@radix-ui/react-label";

import { cn } from "@/lib/utils";

export const Label = ({ className, ...props }: LabelProps) => (
  <Root
    className={cn(
      "text-headline leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
      className
    )}
    {...props}
  />
);
