import { ButtonHTMLAttributes, MouseEvent } from "react";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  id: string;
  active?: boolean;
};

export type ButtonGroupProps = {
  buttons: ButtonProps[];
};

const disabledClass = "cursor-not-allowed";

const activeClass = "!bg-background !shadow-sm";

const Button = ({ onClick, disabled, id, children, active }: ButtonProps) => (
  <button
    type="button"
    className={`text-subtext-bold flex items-center rounded-md py-[0.4375rem] pl-3 pr-3 ${
      disabled ? disabledClass : ""
    } ${active ? activeClass : ""}`}
    onClick={onClick}
    disabled={disabled}
    id={id}
    role="tab"
    aria-selected={active}
  >
    {children}
  </button>
);

export const ButtonGroup = ({ buttons }: ButtonGroupProps) => {
  return (
    <div
      className="flex max-w-fit shrink gap-1 space-x-1 whitespace-nowrap rounded-lg bg-inactive p-1 text-text transition-colors"
      role="tablist"
      aria-orientation="horizontal"
    >
      {buttons.map((button) => (
        <Button {...button} key={button.id} />
      ))}
    </div>
  );
};
