import { cn } from "@/lib/utils";
import React from "react";

type SectionHeaderProps = {
  className?: string;
  children: React.ReactNode;
  subtext?: React.ReactNode;
};

export const SectionHeader = ({
  className,
  children,
  subtext,
}: SectionHeaderProps) => (
  <div className={cn("mb-8 flex flex-col", className)}>
    <h2 className={cn("text-title-3 font-bold", subtext && "mb-4")}>
      {children}
    </h2>
    {subtext && <p className="text-headline text-gray-500">{subtext}</p>}
  </div>
);
