import { Label } from "@/components/base/Label";
import { cn } from "@/lib/utils";
import { InputHTMLAttributes, useRef } from "react";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  helperText?: string;
  children?: never;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
};

export const Input = ({
  className,
  type,
  name,
  label,
  helperText,
  prefixIcon,
  suffixIcon,
  ...props
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div className={cn("grid w-full items-center gap-1", className)}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div className="flex rounded-md border border-input has-[:focus]:outline-none has-[:focus]:ring-2 has-[:focus]:ring-ring">
        {prefixIcon && (
          <div
            className="text-headline flex h-12 items-center p-2"
            onClick={handleIconClick}
          >
            {prefixIcon}
          </div>
        )}
        <input
          ref={inputRef}
          id={name}
          type={type}
          className={cn(
            "text-body file:text-body h-12 flex-grow rounded-md bg-card px-3 py-2 file:font-medium read-only:bg-background focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:bg-background disabled:opacity-50",
            prefixIcon && "rounded-l-none",
            suffixIcon && "rounded-r-none"
          )}
          {...props}
        />
        {suffixIcon && (
          <div
            className="text-headline flex h-12 items-center p-2"
            onClick={handleIconClick}
          >
            {suffixIcon}
          </div>
        )}
      </div>
      {helperText && (
        <p className="text-tx-foreground text-subtext pl-2">{helperText}</p>
      )}
    </div>
  );
};
