import { Avatar } from "@/components/base/Avatar";
import { Button } from "@/components/base/Button";
import { SectionHeader } from "@/components/base/SectionHeader";
import { Toggle } from "@/components/base/Toggle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Screen3 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [bankSelected, setBankSelected] = useState(false);

  return (
    <div
      className="mt-8 flex w-full flex-col gap-4 md:flex-row"
      style={{
        minHeight: "calc(100vh - 220px)",
      }}
    >
      <div className="w-full md:w-1/3">
        <SectionHeader subtext={t("screen3.subtext")}>
          {t("screen3.title")}
        </SectionHeader>
      </div>
      <div className="flex min-h-full w-full flex-grow flex-col items-start gap-8 md:w-2/3">
        <Toggle
          pressed={bankSelected}
          onPressedChange={setBankSelected}
          defaultPressed={false}
          className="flex h-min w-max flex-col items-center gap-2 p-4"
        >
          <Avatar
            src="/img/caixa-bank-logo.png"
            alt="Caixa Bank"
            variant="squaricle"
            className="w-32"
          />
          Caixa Bank
        </Toggle>
        <div>
          <Button onClick={() => navigate("/screen4")} disabled={!bankSelected}>
            {t("screen2.button")}
          </Button>
        </div>
      </div>
    </div>
  );
};
