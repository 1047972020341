import { Button } from "@/components/base/Button";
import { SectionHeader } from "@/components/base/SectionHeader";
import { useSession } from "@/context/session-provider";
import { ArrowRight } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Screen1 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fetchSession } = useSession();

  const handleSessionAndNavigate = async () => {
    try {
      await fetchSession(null);
      navigate("/screen2");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-8 flex w-full flex-col gap-4 md:flex-row">
      <div className="w-full md:w-1/3">
        <SectionHeader>{t("screen1.title")}</SectionHeader>
      </div>
      <div className="flex w-full flex-col gap-8 md:w-2/3">
        <span className="text-headline text-tx-foreground">
          {t("screen1.body1")}
        </span>
        <span className="text-headline text-tx-foreground">
          {t("screen1.body2")}
        </span>
        <span className="text-headline text-tx-foreground">
          {t("screen1.body3", { x: "45", y: "100" })}
        </span>
        <div>
          <Button
            onClick={() => void handleSessionAndNavigate()}
            className="gap-1"
          >
            {t("screen1.button")} <ArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
};
