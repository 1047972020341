import { Loading } from "@/components/module/Loading";
import { RedirectToSignIn } from "@/components/module/RedirectToSignIn";
import { setLocalStorage } from "@/lib/utils";
import { UserBankBody, UserSessionBody } from "@/types/session";
import { useAuth0 } from "@auth0/auth0-react";
import { captureException } from "@sentry/react";
import { createContext, useContext, useState } from "react";

type UserProviderProps = {
  children: React.ReactNode;
};

type UserProviderState = {
  login: () => void;
  logout: () => void;
  error: Error | null;
  isAuthenticated: boolean;
  auth0Loading: boolean;
  getAccessToken: () => Promise<string | null>;
  userData: UserSessionBody;
  changeUserData: (label: keyof UserSessionBody, value: string) => void;
  userBankData: UserBankBody;
  changeUserBankData: (label: keyof UserBankBody, value: string) => void;
};

const initialState: UserProviderState = {
  login: () => null,
  logout: () => null,
  error: null,
  isAuthenticated: false,
  auth0Loading: true,
  getAccessToken: () => Promise.resolve(""),
  userData: {
    first_name: "",
    first_surname: "",
    last_surname: "",
    id: "",
  },
  changeUserData: () => null,
  userBankData: {
    name: "AMPARO MARIA PADILLA ALVAREZ",
    iban: "ES77 1465 0100 98 1729058512",
    identifier: "",
    password: "",
  },
  changeUserBankData: () => null,
};

const UserProviderContext = createContext<UserProviderState>(initialState);

const userState: UserSessionBody = initialState.userData;

const userBankState: UserBankBody = initialState.userBankData;

export const UserProvider = ({ children }: UserProviderProps) => {
  const {
    isLoading,
    user: auth0User,
    error,
    loginWithRedirect,
    logout: authLogout,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const [userData, changeData] = useState(userState);
  const [userBankData, changeBankData] = useState(userBankState);

  const changeUserData = (label: keyof UserSessionBody, value: string) => {
    changeData({ ...userData, [label]: value });
  };

  const changeUserBankData = (label: keyof UserBankBody, value: string) => {
    changeBankData({ ...userBankData, [label]: value });
  };

  const login = async () => {
    if (window.location.pathname !== "/") {
      setLocalStorage("returnTo", window.location.pathname);
    }
    await loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };

  const logout = async () => {
    await authLogout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error(error);
      await login();
      return null;
    }
  };

  // const getUserData = async () => {
  //   if (isLoading || !auth0User || !isAuthenticated) {
  //     return;
  //   }
  //   try {
  //     const token = await getAccessTokenSilently();

  //     const current_user = await getCurrentUser(token, () => void login);

  //     if (current_user && !_.isEqual(user, { ...auth0User, ...current_user })) {
  //       setUser({
  //         ...auth0User,
  //         ...current_user,
  //       });
  //     }
  //   } catch (e) {
  //     if (isAxiosError(e)) {
  //       if (e.response?.status === 401) {
  //         login().catch(console.error);
  //       }
  //     }
  //     throw e;
  //   }
  // };

  // useEffect(() => {
  //   getUserData().catch(console.error);

  //   const interval = setInterval(() => {
  //     getUserData().catch(console.error);
  //   }, 15 * 1000);

  //   return () => clearInterval(interval);
  // }, [isAuthenticated]);

  const value = {
    error: error ?? null,
    login,
    logout,
    isAuthenticated,
    auth0Loading: isLoading,
    getAccessToken,
    userData,
    changeUserData,
    userBankData,
    changeUserBankData,
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated || !auth0User) {
    login().catch(console.error);
    return <RedirectToSignIn />;
  }

  if (error) {
    console.error(error);
    captureException(error);
  }

  return (
    <UserProviderContext.Provider value={value}>
      {children}
    </UserProviderContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserProviderContext);

  if (context === undefined)
    throw new Error("useUser must be used within a UserProvider");

  return context;
};
