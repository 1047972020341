import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { cn } from "@/lib/utils";
import { ReactNode } from "react";

type _AvatarProps = AvatarPrimitive.AvatarProps & {
  className?: ReactNode;
  variant?: "circle" | "squaricle" | "square";
};

const AvatarRoot = ({
  className,
  variant = "circle",
  ...props
}: _AvatarProps) => (
  <AvatarPrimitive.Root
    className={cn(
      "relative flex h-10 w-10 shrink-0 overflow-hidden",
      variant === "circle" && "rounded-full",
      variant === "squaricle" && "rounded-md",
      className
    )}
    {...props}
  />
);

const AvatarImage = ({ className, ...props }: _AvatarProps) => (
  <AvatarPrimitive.Image
    className={cn("aspect-square h-full w-full", className)}
    {...props}
  />
);

const AvatarFallback = ({ className, ...props }: _AvatarProps) => (
  <AvatarPrimitive.Fallback
    className={cn(
      "flex h-full w-full items-center justify-center rounded-full bg-inactive",
      className
    )}
    {...props}
  />
);

type AvatarPropsBase = _AvatarProps & {
  src?: string;
  alt?: string;
  children?: ReactNode;
};

type AvatarWithImageProps = AvatarPropsBase & {
  src: string;
  alt: string;
  children?: never;
};

type AvatarWithFallbackProps = AvatarPropsBase & {
  src?: never;
  alt?: never;
  children: ReactNode;
};

type AvatarProps = AvatarWithImageProps | AvatarWithFallbackProps;

export const Avatar = ({ ...props }: AvatarProps) => (
  <AvatarRoot {...props}>
    <AvatarImage {...props} />
    <AvatarFallback {...props} />
  </AvatarRoot>
);
