import ReactDOM from "react-dom/client";
import { App } from "./App.tsx";
import "./index.css";

import "./i18n/config.ts";

// init({
//   environment: import.meta.env.MODE,
//   dsn: "https://31d94598888c73b27d2bdb07c3fe448a@o4506672568729600.ingest.sentry.io/4506676089651200",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: reactRouterV6Instrumentation(
//         useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//     replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   attachStacktrace: true,
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <App />
  // </StrictMode>
);
