import { Model, ModelOptions } from "@/types/models";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const isValidJsonString = (str: string): boolean => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const obj: Record<string, unknown> = JSON.parse(str);
    return obj && typeof obj === "object";
  } catch (e) {
    return false;
  }
};

export const getLocalStorage = <T>(storageKey: string): T | null => {
  const value = localStorage.getItem(storageKey);
  if (value !== null) {
    try {
      return JSON.parse(value) as T;
    } catch {
      return null;
    }
  }
  return null;
};

export const setLocalStorage = <T>(key: string, value: T): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const truncateString = (str: string): string =>
  str.length <= 7 ? str : `${str.slice(0, 3)}...${str.slice(-4)}`;

export const copyToClipboard = (text: string): Promise<void> => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    return new Promise((resolve, reject) => {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        resolve();
      } catch (err) {
        reject(err);
      } finally {
        document.body.removeChild(textarea);
      }
    });
  }
};

export const extractModelId = (
  models:
    | ({
        label: string;
        value: string;
      } & Model)[]
    | null,
  currentData: {
    model: string | null;
  } | null
): string | undefined =>
  models?.find((model) => {
    if (currentData != null && currentData.model != null) {
      const [modelId, modelCreated] = currentData.model.split("__");

      return (
        modelId === model.id.toLocaleLowerCase() &&
        modelCreated === `${model.created}`
      );
    }
  })?.id;

export const deleteProperty = <T extends object>(obj: T, prop: string): T => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- used to remove prop from obj
  const { [prop]: _, ...newObj } = obj as { [key: string]: unknown };
  return newObj as T;
};

export const getModel = (models: ModelOptions[], modelName: string) =>
  models.find((model) => model.value.includes(modelName));

type GroupedModel = {
  label: string;
  options: ModelOptions[];
};

export const groupModelsByCategory = (
  models: ModelOptions[]
): GroupedModel[] => {
  const grouped = models.reduce(
    (acc: Record<string, ModelOptions[]>, model) => {
      if (!acc[model.category]) {
        acc[model.category] = [];
      }
      acc[model.category].push(model);
      return acc;
    },
    {}
  );

  return Object.entries(grouped).map(([label, options]) => ({
    label,
    options,
  }));
};
