import { editSession, getSession } from "@/apis/sessions";
import { isAxiosError } from "@/lib/http";
import { Session, UserBankBody, UserSessionBody } from "@/types/session";
import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import _ from "underscore";
import { useUser } from "./user-provider";

type SessionProviderProps = {
  children: React.ReactNode;
};

type SessionProviderState = {
  session: Session | null;
  loading: boolean;
  fetchSession: (data: UserSessionBody | UserBankBody | null) => Promise<void>;
};

const initialState: SessionProviderState = {
  session: null,
  loading: false,
  fetchSession: () => Promise.resolve(),
};

const SessionProviderContext =
  createContext<SessionProviderState>(initialState);

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const { login, getAccessToken } = useUser();
  const [session, setSession] = useState<
    SessionProviderState["session"] | null
  >(initialState.session);
  const [loading, setLoading] = useState(initialState.loading);

  const fetchSession = async (data: UserSessionBody | UserBankBody | null) => {
    setLoading(true);
    if (!window.$chatwoot) {
      toast.error("Chatwoot is not available");
      return;
    }
    try {
      const token = await getAccessToken();
      let responseData: Session | undefined | null = null;
      if (session != null && data != null && "id" in data) {
        responseData = await editSession(token ?? "", login, session.id, data);
      } else if (session != null && data != null && "iban" in data) {
        responseData = await editSession(token ?? "", login, session.id, data);
      } else {
        responseData = await getSession(token ?? "", login);
      }

      if (responseData?.context?.customer?.first_name) {
        window.$chatwoot.setUser(responseData?.id ?? "", {
          name: responseData?.context?.customer.first_name ?? "Guest",
        });
      }

      if (responseData?.id) {
        window.$chatwoot.setCustomAttributes({
          session_id: responseData.id,
        });
        window.$chatwoot.setConversationCustomAttributes({
          session_id: responseData.id,
        });
      }

      if (responseData && !_.isEqual(session, responseData)) {
        setSession(responseData);
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          login();
        }
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    session,
    loading,
    fetchSession,
  };

  return (
    <SessionProviderContext.Provider value={value}>
      {children}
    </SessionProviderContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionProviderContext);

  if (context === undefined)
    throw new Error("useSession must be used within a SessionProvider");

  return context;
};
