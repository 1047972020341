import { ChatwootWidget } from "@/components/module/Chatwoot";
import { useSession } from "@/context/session-provider";
import { useUser } from "@/context/user-provider";
import { useEffect } from "react";
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Screen1 } from "./Screens/Screen1";
import { Screen2 } from "./Screens/Screen2";
import { Screen3 } from "./Screens/Screen3";
import { Screen4 } from "./Screens/Screen4";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useSession();
  const { isAuthenticated, login } = useUser();

  useEffect(() => {
    if (location.pathname !== "/" && !session) {
      navigate("/");
      // if (typeof window !== "undefined" && window.$chatwoot) {
      //   window.$chatwoot.toggleBubbleVisibility("show");
      // }
    }
  }, [location.pathname, session]);

  useEffect(() => {
    if (!isAuthenticated) {
      login();
    }
  }, [isAuthenticated]);

  return (
    <div className="flex w-full flex-col gap-8">
      {location.pathname === "/" ? <Screen1 /> : <Outlet />}
      {/* {!new URLSearchParams(location.search).get("enableChatwoot") &&
        session && <ChatBox />} */}
      <ChatwootWidget />
    </div>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="screen2" element={<Screen2 />} />
      <Route path="screen3" element={<Screen3 />} />
      <Route path="screen4" element={<Screen4 />} />
      <Route path="*" element={<Screen1 />} />
    </Route>
  )
);
