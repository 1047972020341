import { handleAxiosError, httpClient } from "@/lib/http";
import {
  Session,
  SessionChatData,
  UserBankBody,
  UserSessionBody,
} from "@/types/session";

export const getSession = async (accessToken: string, login: () => void) => {
  try {
    const response = await httpClient.post<{
      session: Session;
    }>(
      "/v1/sessions",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data.session;
  } catch (e) {
    handleAxiosError(e, login);
  }
};

export const editSession = async (
  accessToken: string,
  login: () => void,
  sessionID: string,
  data: UserSessionBody | UserBankBody
) => {
  try {
    let dataToSend;
    if ("iban" in data) {
      dataToSend = {
        context: {
          expected_bank_details: data,
        },
      };
    } else {
      dataToSend = {
        context: {
          customer: data,
        },
      };
    }
    const response = await httpClient.put<{
      session: Session;
    }>(`/v1/sessions/${sessionID}`, dataToSend, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data.session;
  } catch (e) {
    handleAxiosError(e, login);
  }
};

export const getSessionMessages = async (
  accessToken: string,
  login: () => void,
  sessionID: string
) => {
  try {
    const response = await httpClient.get<SessionChatData>(
      `/v1/sessions/${sessionID}/chat/messages`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (e) {
    handleAxiosError(e, login);
  }
};

export const postSessionMessageWithoutFile = async (
  accessToken: string,
  login: () => void,
  sessionID: string,
  message: string
) => {
  try {
    const response = await httpClient.post<SessionChatData>(
      `/v1/sessions/${sessionID}/chat/messages`,
      {
        content: message,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (e) {
    handleAxiosError(e, login);
  }
};

export const postSessionMessageWithFile = async (
  accessToken: string,
  login: () => void,
  sessionID: string,
  message: string,
  files: File[]
) => {
  const formData = new FormData();
  formData.append("content", message);
  for (const file of files) {
    formData.append("files", file);
  }

  try {
    const response = await httpClient.post<SessionChatData>(
      `/v1/sessions/${sessionID}/chat/messages/files`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (e) {
    handleAxiosError(e, login);
  }
};
