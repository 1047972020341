import { Button } from "@/components/base/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/base/Collapsible";
import { Input } from "@/components/base/Input";
import { SectionHeader } from "@/components/base/SectionHeader";
import { useSession } from "@/context/session-provider";
import { useUser } from "@/context/user-provider";
import { CaretUpDown } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const Screen4 = () => {
  const { t } = useTranslation();
  const { fetchSession } = useSession();
  const { userBankData, changeUserBankData } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  const handleSessionData = async () => {
    try {
      await fetchSession(userBankData);
      toast.success(t("screen4.success"));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="mt-8 flex w-full flex-col gap-4 md:flex-row"
      style={{
        minHeight: "calc(100vh - 220px)",
      }}
    >
      <div className="w-full md:w-1/3">
        <SectionHeader subtext={t("screen4.subtext")}>
          {t("screen4.title")}
        </SectionHeader>
      </div>
      <div className="flex w-full flex-col gap-8 md:w-2/3">
        <Input
          name="username"
          label={t("screen4.usernameLabel")}
          helperText={t("screen4.usernameHelper")}
          required
          value={userBankData.identifier}
          onChange={(e) => changeUserBankData("identifier", e.target.value)}
        />
        <Input
          name="password"
          type="password"
          label={t("screen4.passwordLabel")}
          helperText={t("screen4.passwordHelper")}
          required
          value={userBankData.password}
          onChange={(e) => changeUserBankData("password", e.target.value)}
        />
        {/* <Input
          name="folder"
          label={t("screen4.optionalFolderLabel")}
          value={userBankData.optional_folder}
          onChange={(e) =>
            changeUserBankData("optional_folder", e.target.value)
          }
        /> */}
        <div>
          <Button
            disabled={
              !userBankData.iban ||
              !userBankData.identifier ||
              !userBankData.password
            }
            onClick={() => void handleSessionData()}
          >
            {t("screen4.button")}
          </Button>
        </div>

        <Collapsible
          open={isOpen}
          onOpenChange={setIsOpen}
          className="w-full space-y-2"
        >
          <div className="flex w-full items-center justify-end space-x-4 px-4">
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm" className="gap-2">
                {t("screen4.moreInfo")}
                <CaretUpDown className="h-4 w-4" />
                <span className="sr-only">Toggle</span>
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent className="flex w-full flex-col gap-8">
            <Input
              name="iban"
              label={t("screen4.ibanLabel")}
              helperText={t("screen4.ibanHelper")}
              required
              value={userBankData.iban}
              onChange={(e) => changeUserBankData("iban", e.target.value)}
            />
            <Input
              name="name"
              label={t("screen2.fullNameLabel")}
              required
              value={userBankData.name}
              onChange={(e) => changeUserBankData("name", e.target.value)}
            />
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
};
