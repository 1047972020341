import { Auth0Provider } from "@auth0/auth0-react";
import { IconContext } from "@phosphor-icons/react";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import { useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonGroup } from "./components/base/ButtonGroup";
import { Topbar } from "./components/module/Topbar";
import { SessionProvider } from "./context/session-provider";
import { UserProvider } from "./context/user-provider";
import { router } from "./views/AppRouter";

const auth0Domain = import.meta.env.VITE_PUBLIC_AUTH0_DOMAIN;
const auth0ClientId = import.meta.env.VITE_PUBLIC_AUTH0_CLIENT_ID;
const auth0Audience = import.meta.env.VITE_PUBLIC_AUTH0_AUDIENCE;

export const App = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (val: string) => {
    void i18n.changeLanguage(val);
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: auth0Audience,
      }}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <UserProvider>
        <SessionProvider>
          <IconContext.Provider
            value={{
              size: 24,
              mirrored: false,
            }}
          >
            <Topbar borderBottom>
              <h1 className="text-page-title">Fintonic</h1>
              <ButtonGroup
                buttons={[
                  {
                    children: "EN",
                    onClick: () => changeLanguage("en"),
                    id: "en",
                    active: i18n.language === "en",
                  },
                  {
                    children: "ES",
                    onClick: () => changeLanguage("es"),
                    id: "es",
                    active: i18n.language === "es",
                  },
                ]}
              />
            </Topbar>
            <main className="min-h-[calc(100vh_-_79px)] flex-1 flex-col px-10 py-4">
              <RouterProvider router={router} />
            </main>
          </IconContext.Provider>
          <ToastContainer
            closeButton={false}
            draggable={false}
            hideProgressBar
            pauseOnFocusLoss={false}
            position="top-center"
            theme="colored"
            autoClose={3000}
            closeOnClick
            transition={Zoom}
            icon={false}
            limit={3}
          />
        </SessionProvider>
      </UserProvider>
    </Auth0Provider>
  );
};
