import { getLocalStorage, setLocalStorage } from "@/lib/utils";
import { createContext, useContext, useEffect, useState } from "react";

type Theme = "light" | "dark" | "system";

type LocalStorage = {
  theme: Theme;
  sidebarExpanded: boolean;
  // sidebarPinned: boolean;
};

type AppProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type AppProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  sidebarExpanded: boolean;
  setSidebarExpanded: (expanded: boolean) => void;
  // sidebarPinned: boolean;
  // setSidebarPinned: (pinned: boolean) => void;
  topbarName: string;
  setTopbarName: (name: string) => void;
  topbarBottomBorder: boolean;
  setTopbarBottomBorder: (divider: boolean) => void;
  topbarCustomActions: React.ReactNode | null;
  setTopbarCustomActions: (actions: React.ReactNode) => void;
};

const initialState: AppProviderState = {
  theme: "system",
  setTheme: () => null,
  sidebarExpanded: true,
  setSidebarExpanded: () => null,
  // sidebarPinned: false,
  // setSidebarPinned: () => null,
  topbarName: "Home",
  setTopbarName: () => null,
  topbarBottomBorder: true,
  setTopbarBottomBorder: () => null,
  topbarCustomActions: undefined,
  setTopbarCustomActions: () => null,
};

const AppProviderContext = createContext<AppProviderState>(initialState);

export const AppProvider = ({
  children,
  defaultTheme = "system",
  storageKey = "ui-state",
  ...props
}: AppProviderProps) => {
  const [theme, setTheme] = useState<Theme>(
    getLocalStorage<LocalStorage>(storageKey)?.theme ?? defaultTheme
  );
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(
    getLocalStorage<LocalStorage>(storageKey)?.sidebarExpanded ?? true
  );
  // const [sidebarPinned, setSidebarPinned] = useState<boolean>(
  //   getLocalStorage<LocalStorage>(storageKey)?.sidebarPinned ?? false
  // );
  const [topbarName, setTopbarName] = useState<string>("Home");
  const [topbarBottomBorder, setTopbarBottomBorder] = useState<boolean>(true);
  const [topbarCustomActions, setTopbarCustomActions] =
    useState<React.ReactNode>();

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove("light", "dark");

    if (theme === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const setLocalStorageData = (key: string, value: unknown) => {
    const currentData = getLocalStorage<LocalStorage>(storageKey);
    setLocalStorage(storageKey, { ...currentData, [key]: value });
  };

  const value = {
    theme,
    setTheme: (theme: Theme) => {
      setLocalStorageData("theme", theme);
      setTheme(theme);
    },
    sidebarExpanded,
    setSidebarExpanded: (sidebarExpanded: boolean) => {
      setLocalStorageData("sidebarExpanded", sidebarExpanded);
      setSidebarExpanded(sidebarExpanded);
    },
    // sidebarPinned,
    // setSidebarPinned: (sidebarPinned: boolean) => {
    //   setLocalStorageData("sidebarPinned", sidebarPinned);
    //   setSidebarPinned(sidebarPinned);
    // },
    topbarName,
    setTopbarName: (topbarName: string) => setTopbarName(topbarName),
    topbarBottomBorder,
    setTopbarBottomBorder: (topbarBottomBorder: boolean) =>
      setTopbarBottomBorder(topbarBottomBorder),
    topbarCustomActions,
    setTopbarCustomActions: (topbarCustomActions: React.ReactNode) =>
      setTopbarCustomActions(topbarCustomActions),
  };

  return (
    <AppProviderContext.Provider {...props} value={value}>
      {children}
    </AppProviderContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppProviderContext);

  if (context === undefined)
    throw new Error("useApp must be used within a AppProvider");

  return context;
};
