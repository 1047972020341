import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/en/translations.json";
import esTranslations from "./locales/es/translations.json";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",
    resources: {
      en: {
        translations: enTranslations,
      },
      es: {
        translations: esTranslations,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  })
  .catch(console.error);

i18n.languages = ["en", "es"];

export default i18n;
