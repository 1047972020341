import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";
import { ButtonHTMLAttributes, forwardRef } from "react";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-headline text-tx ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90 ",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-card hover:bg-accent hover:text-accent-foreground",
        ghost:
          "hover:bg-accent hover:text-accent-foreground disabled:cursor-not-allowed disabled:ring-transparent disabled:ring-offset-transparent disabled:text-current disabled:bg-transparent disabled:hover:bg-transparent disabled:hover:text-current disabled:hover:ring-transparent disabled:hover:",
        link: "text-primary underline-offset-4 hover:underline hover:text-accent-foreground",
        selected: "bg-accent text-accent-foreground",
        success:
          "bg-accent-foreground text-accent hover:bg-accent-foreground/90",
        transparent:
          "bg-transparent hover:text-accent-foreground text-sm disabled:cursor-not-allowed disabled:ring-transparent disabled:ring-offset-transparent disabled:text-current disabled:bg-transparent disabled:hover:bg-transparent disabled:hover:text-current disabled:hover:ring-transparent disabled:hover:",
        muted:
          "bg-inactive disabled:cursor-not-allowed disabled:ring-transparent disabled:ring-offset-transparent disabled:text-current disabled:bg-transparent disabled:hover:bg-transparent disabled:hover:text-current disabled:hover:ring-transparent disabled:hover:",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        full: "h-10 w-full",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
    selected?: boolean;
  };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      loading,
      selected,
      ...props
    }: ButtonProps,
    ref
  ) => (
    <button
      ref={ref}
      className={`${cn(buttonVariants({ variant, size, className }))} ${
        selected ? "bg-accent text-accent-foreground" : ""
      }`}
      disabled={loading}
      role={variant === "link" ? "link" : "button"}
      {...props}
    >
      {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      {children}
    </button>
  )
);
Button.displayName = "Button";

export { Button, buttonVariants };
