import { cn } from "@/lib/utils";

type TopbarProps = {
  children: React.ReactNode;
  borderBottom?: boolean;
};

export const Topbar = ({ children, borderBottom = true }: TopbarProps) => (
  <header
    className={cn(
      "flex items-start justify-between whitespace-nowrap px-10 py-4",
      borderBottom && "border-b border-solid border-b-neutral-300"
    )}
  >
    {children}
  </header>
);
