import { Button } from "@/components/base/Button";
import { Input } from "@/components/base/Input";
import { SectionHeader } from "@/components/base/SectionHeader";
import { useSession } from "@/context/session-provider";
import { useUser } from "@/context/user-provider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Screen2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { session, fetchSession } = useSession();
  const { userData, changeUserData } = useUser();

  const handleSessionDataAndNavigate = async () => {
    try {
      await fetchSession(userData);
      navigate("/screen3");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (session && session.context?.customer) {
      changeUserData("first_name", session.context.customer.first_name);
      changeUserData("first_surname", session.context.customer.first_surname);
      changeUserData("last_surname", session.context.customer.last_surname);
      changeUserData("id", session.context.customer.id);
    }
  }, [session]);

  return (
    <div className="mt-8 flex w-full flex-col gap-4 md:flex-row">
      <div className="w-full md:w-1/3">
        <SectionHeader subtext={t("screen2.subtext")}>
          {t("screen2.title")}
        </SectionHeader>
      </div>
      <div className="flex w-full flex-col gap-8 md:w-2/3">
        <Input
          name="full-name"
          label={t("screen2.fullNameLabel")}
          required
          value={userData.first_name}
          onChange={(e) => changeUserData("first_name", e.target.value)}
        />
        <Input
          name="first-surname"
          label={t("screen2.firstSurnameLabel")}
          required
          value={userData.first_surname}
          onChange={(e) => changeUserData("first_surname", e.target.value)}
        />
        <Input
          name="second-surname"
          label={t("screen2.secondSurnameLabel")}
          required
          value={userData.last_surname}
          onChange={(e) => changeUserData("last_surname", e.target.value)}
        />
        <Input
          name="id"
          label={t("screen2.ID")}
          required
          value={userData.id}
          onChange={(e) => changeUserData("id", e.target.value)}
        />
        <div>
          <Button
            onClick={() => void handleSessionDataAndNavigate()}
            disabled={
              !userData.first_name ||
              !userData.first_surname ||
              !userData.last_surname ||
              !userData.id
            }
          >
            {t("screen2.button")}
          </Button>
        </div>
      </div>
    </div>
  );
};
